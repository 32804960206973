.servicios {
  min-height: calc(100vh - var(--nav-height));

  &--item {
    position: relative;
    margin-bottom: 0.5em;
    width: 66%;
    margin-left: auto;
  }

  &--photo {
    margin-top: 20vh;
    height: 50vh;

    img {
      @include fitimg;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .servicios {
    min-height: 0;
    height: calc(100vh - var(--nav-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .container {
      height: auto;
    }

    &--list {
      display: flex;
      flex-wrap: wrap;
    }

    &--item {
      width: auto;
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &--photo {
      margin-top: 0;
      height: calc((100vh - var(--nav-height)) / 2);
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .servicios {
    height: auto;

    &--list {
      width: 66%;
      margin-left: auto;
      justify-content: flex-end;
      column-gap: 1em;
    }

    &--item {
      flex-basis: 30%;
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 0;
    }

    &--photo {
      margin-top: 10vh;
      height: 62vh;
    }
  }
}
