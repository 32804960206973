.footer {
  background-color: black;
  color: white;
  padding-top: 10vh;
  padding-bottom: 10vh;

  &--contact {
    margin-bottom: 2em;

    &__item {
      height: 2em;
      display: flex;
      align-items: center;
      gap: 2em;
      margin-bottom: 1em;
      // border: solid yellow thin;

      &-icon {
        height: 100%;
        // border: solid red thin;

        img {
          @include fitimg;
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  &--small {
    display: flex;
    justify-content: space-between;
    font-size: 0.7em;
  }

  &--aviso {
    color: inherit;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .footer {
    &--contact {
      display: flex;
      justify-content: space-between;
      font-size: 2em;
    }

    &--small {
      justify-content: center;
      gap: 2em;
    }
  }
}
