.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  background-color: var(--color-background);
  // border-bottom: solid #f4f4f4 thin;
  // padding: 0.5em 0;
  z-index: 666;

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--logo {
    height: 100%;
    width: 4em;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &--links {
    display: none;
  }

  &--mobile {
    display: flex;
    gap: 1em;
    // border: solid blue;

    &__item {
      --size: 2.5em;
      height: var(--size);
      width: var(--size);
      border-radius: 50%;
      border-style: solid;
      border-color: var(--color-navButton);
      border-width: 2px;
      // background-color: var(--color-background);
      background-color: var(--color-navButton);
      overflow: hidden;
      padding: 0.5em;

      img {
        @include fitimg;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .nav {
    &--mobile {
      gap: 1.5em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large} * 1em)) {
  .nav {
    // border-bottom: solid green;

    &--mobile {
      display: none;
    }

    &--links {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 3em;

      &__item {
        a {
          font-weight: 300;
          color: black;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-xlarge}*1em)) {
  .nav {
    // border-bottom: solid red;
  }
}
