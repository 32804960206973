body {
  font-size: 16px;
  // font-family: "Reaktif", sans-serif;
  font-family: "Neue Haas Display", sans-serif;
  // min-height: 100%;
  // font-weight: 600;
  // font-weight: 700;
  background-color: var(--color-background);
}

.container {
  margin: 0 auto;
  // max-width: $container-maxwidth;
  height: 100%;
  padding: var(--container-padding);
}

section {
  margin-top: var(--section-margin);
}

ul {
  list-style: none;
}

.row {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-height: 600px) {
  body {
    font-size: 14px;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  section {
    // margin-top: 0;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  section {
  }
}
