.proyectos {
  &--item {
    margin-bottom: 4em;

    &__photo {
      height: 40vh;

      img {
        @include fitimg;
      }
    }

    &__name {
      margin-top: 0.5em;
      font-size: 0.9em;
      font-weight: 300;
    }

    a {
      display: block;
      color: black;
      margin-left: auto;
      width: fit-content;
    }
  }
}

// Tall phones
@media screen and (min-height: 700px) and (max-width: calc(#{$bp-medium}*1em)) {
  .proyectos {
    margin-top: calc(var(--section-margin) / 2);

    &--item {
      &__photo {
        height: 30vh;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .proyectos {
    margin-top: 0;

    &--item {
      &__photo {
        height: 30vh;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .proyectos {
    // height: calc(100vh - #{$nav-height});
    margin-top: var(--section-margin);

    &--row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &--item {
      flex-basis: 30%;

      &__photo {
        overflow: hidden;
        height: 50vh;
      }

      &__name {
        margin-top: 1em;
      }
    }
  }
}
