@font-face {
  font-family: "Neue Haas Display";
  src: url("/static/fonts/NeueHaasDisplay-Roman.woff");
  font-weight: 400;
  font-style: "normal";
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("/static/fonts/NeueHaasDisplay-Medium.woff");
  font-weight: 500;
  font-style: "normal";
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("/static/fonts/NeueHaasDisplay-Light.woff");
  font-weight: 300;
  font-style: "normal";
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("/static/fonts/NeueHaasDisplay-Bold.woff");
  font-weight: 700;
  font-style: "normal";
}
