.beneficios {
  min-height: 100vh;
  overflow: hidden;

  &--item {
    margin-bottom: 4em;

    .container {
      min-height: calc((100vh - #{$nav-height}) * 0.4);
    }

    &__titleContainer {
      display: grid;
      grid-template-columns: 30% 70%;
    }

    &__number {
      font-size: 4em;
      font-weight: 700;
      color: var(--color-nygaccent);
    }

    &__text {
      font-weight: 300;
      width: 70%;
      margin-left: auto;
      margin-bottom: 1em;
    }

    &__photo {
      height: calc((100vh - #{$nav-height}) * 0.3);
      visibility: hidden;
      overflow: hidden;

      img {
        @include fitimg;
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
    }

    &__block {
      background-color: var(--color-nygaccent);
      height: calc((100vh - #{$nav-height}) * 0.3);
      flex-basis: 30%;
    }

    &__details {
      font-weight: 300;
      margin: 0.5em 1em;
      font-size: 0.9em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .beneficios {
    min-height: 0;

    &--item {
      margin-bottom: 6em;

      .container {
        min-height: 0;
        height: 30vh;
      }

      &__titleContainer {
        br {
          display: none;
        }
      }

      &__photo {
        height: 32vh;
      }

      &__block {
        height: 20vh;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .beneficios {
    margin-top: 0;

    &--item {
      height: 33vh;
      margin-bottom: 0;
      display: flex;

      .container {
        height: 100%;
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0;
        padding-left: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__titleContainer {
        margin-top: -1em;
        display: flex;

        br {
          display: initial;
        }
      }

      &__number {
        width: 6rem;
      }

      &__text {
        margin-left: 6rem;
        max-width: 20em;
      }

      &__row {
        position: relative;
        display: block;
        flex-basis: 25%;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &__block {
        height: 100%;
        width: 100%;
        background-color: white;
      }

      &__details {
        display: none;
        position: absolute;
        color: white;
        top: 1em;
        left: 1em;
      }

      &__photo {
        margin-top: 0;
        height: 100%;
        flex-shrink: 0;
        flex-basis: 25%;
        order: 2;
      }

      // Second
      &:nth-of-type(3) {
        .container {
          order: 1;
        }

        .beneficios--item__photo {
          order: 2;

          img {
            display: none;
          }
        }

        .beneficios--item__block {
          order: 0;
          background-color: white;
        }

        .beneficios--item__details {
          display: none;
        }
      }

      // Third
      &:nth-of-type(4) {
        .container {
          order: 2;
        }

        .beneficios--item__photo {
          order: 0;
        }

        .beneficios--item__row {
          order: 1;
        }

        .beneficios--item__block {
          background-color: white;
        }

        .beneficios--item__details {
          display: none;
        }
      }
    }
  }
}
