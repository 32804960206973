.contactBtn {
  --size: 7em;
  display: none;
  position: fixed;
  height: var(--size);
  width: var(--size);
  background-color: black;
  border-radius: 50%;
  z-index: 1000;
  cursor: pointer;
  transition: 0.3s ease 50ms;
  text-align: center;
  color: white;
  animation: spinZ 20s linear infinite;
  opacity: 0;
  transition: 0.5s ease;
  scale: 0.8;
  user-select: none;

  &.visible {
    opacity: 1;
    scale: 1;
  }

  &:hover {
    background-color: var(--color-navButton);
  }

  span {
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: var(--size / 10);
    color: white;
  }

  &--icon {
    --size: 35%;
    position: absolute;
    top: 50%;
    left: 50%;
    height: var(--size);
    width: var(--size);
    transform: translate(-50%, -50%);
    z-index: 2001;
    // border: solid red;

    img {
      @include fitimg;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .contactBtn {
    display: block;
    top: 2rem;
    right: 2rem;
  }
}

@keyframes spinZ {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
