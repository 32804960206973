.features {
  &--titleContainer {
    margin: 0 auto;
    padding: var(--container-padding);
  }

  .container {
    height: auto;
  }

  &--row {
    display: flex;
  }

  &--list {
    flex-basis: 66%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: var(--container-padding);
  }

  &--item {
    position: relative;
    margin-bottom: 10vh;
    width: 8em;

    &__title {
      font-weight: 300;
      font-size: var(--fontsize-tertiarytitle);
    }

    // &[data-state='collapsed'] &__textContainer {
    //   height: 0;
    // }

    // &[data-state='expanded'] &__textContainer {
    //   height: fit-content;
    //   transition: 0.3s ease;
    // }

    &__textContainer {
      // border: solid green thin;
      overflow: hidden;
      height: 0;
    }

    &__text {
      font-weight: 300;
    }

    &__button {
      display: block;
      --button-size: 48px;
      position: absolute;
      top: 0;
      right: calc(-1 * var(--button-size));
      height: var(--button-size);
      width: var(--button-size);
      border: solid var(--color-nygaccent) 3px;
      border-radius: 50%;
      cursor: pointer;
      background-color: transparent;
      z-index: 1;

      &-hline,
      &-vline {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 3px;
        background-color: var(--color-nygaccent);
      }

      &-hline {
        transform: translate(-50%, -50%);
      }

      &-vline {
        transform: translate(-50%, -50%) rotate(90deg);
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include fitimg;
      }
    }
  }

  &--photo {
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(100vh - var(--nav-height));
    overflow: hidden;

    img {
      @include fitimg;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .features {
    &--list {
      display: flex;
      flex-wrap: wrap;
      padding-right: 1em;
    }

    &--item {
      flex-basis: 50%;
      flex-shrink: 0;
      flex-grow: 0;

      &:nth-child(even) {
        margin-left: 50%;
      }

      &__title {
        width: 8em;
      }

      &__text {
        display: inline-block;
        margin-top: 1em;
        font-size: 0.9em;
      }

      &__button {
        display: none;
      }

      &__textContainer {
        height: auto;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .features {
    &--item {
      &__text {
        font-size: 1em;
        width: 15em;
      }
    }
  }
}
