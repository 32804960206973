:root {
  // **************
  // COLORS
  // **************
  // --color-main: #f4f3f1;
  // --color-nygaccent: #db6b2c;
  --color-nygaccent: #000000;
  --color-nygaccent: #59745c;
  // --color-navButton: #59745c;
  --color-navButton: #000000;
  // --color-nygaccent: #788a70;
  // --color-nygaccent: #181146;
  --color-background: #ffffff;
  // --color-background: #eee9dd;
  --color-success: #92aa94;
  --color-error: rgb(227, 53, 53);

  // **************
  // NAV
  // **************
  --nav-height: 3.5em;

  // **************
  // GRID
  // **************
  --columns: 16;
  --col-gap: 1px;
  --col-width: calc((100 / var(--columns)) * 1%);

  // **************
  // CONTAINERS
  // **************
  --container-padding: 0 2rem;
  --container-maxwidth: 1440px;

  // **************
  // FONT SIZES
  // **************
  --fontsize-secondarytitle: 1.953em;
  --fontsize-tertiarytitle: 1.2563em;
  // --fontsize-secondary: 1.563em;

  // **************
  // MISC
  // **************
  --section-margin: 20vh;
}

// **************
// SASS VARIABLES
// **************
// $nav-height: 2.5em;
$nav-height: 3.5em;

$bp-small: 24.15;
$bp-medium: 48; // 768
$bp-large: 60.25; // 964
$bp-xlarge: 75; // 1200

// Font sizes
$mainTitle-fontsize-min: 2.5;
$mainTitle-fontsize-max: 3.25;

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  :root {
    --col-width: calc((100 / 15.5) * 1%);

    --container-padding: 0 3rem;

    // **************
    // FONT SIZES
    // **************
    --fontsize-tertiarytitle: 1.563em;
  }
}
