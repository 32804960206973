.sectionTitle {
  position: relative;
  font-size: 1.5em;
  padding-top: 0.5em;
  margin-top: 1em;
  margin-bottom: 2em;

  &--border {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: black;
    transform-origin: left;
  }

  &--text {
    // font-size: 2em;
    line-height: 1.1;
    font-weight: 300;
  }

  &-parent {
    overflow: hidden;
  }

  &-child {
    display: inline-block;
  }
}

.smallTitle {
  font-size: 1.5em;
  font-size: var(--fontsize-secondarytitle);
  line-height: 1;
  font-weight: 300;
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .sectionTitle {
    font-size: 2em;
    margin-bottom: 3em;
  }
}
