.form {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  width: 100%;
  background-color: rgba(#59745c, 0.5);
  background-color: rgba(#89b48e, 0.7);
  backdrop-filter: blur(6px);
  transition: 0.5s ease-in;
  z-index: 680;

  &.open {
    transition: 0.5s ease-out;
    left: 0;
  }

  .container {
    display: flex;
    align-items: center;
  }

  form {
    margin-top: 1em;
  }

  &--text {
    margin-top: 0.5em;
    font-size: 2em;
    color: rgb(0, 0, 0);

    &.nom {
      margin-top: 0;
    }
  }

  input,
  select {
    display: block;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 1rem;
    font-family: "Neue Haas Display", sans-serif;
    border: none;
    border-bottom: solid black thin;
    outline: none;
    background-color: inherit;
    background-color: rgba(#fff, 0.5);
    border-radius: 15px;

    &::placeholder {
      color: black;
      font-family: "Neue Haas Display", sans-serif;
    }
  }

  &--send {
    position: relative;
    display: block;
    border: none;
    outline: none;
    padding: 0.5em 2em;
    font-size: 1.5em;
    font-weight: 300;
    font-family: "Neue Haas Display", sans-serif;
    letter-spacing: 1px;
    margin: 0 auto;
    margin-top: 1em;
    color: white;
    border-radius: 25px;
    background-color: var(--color-nygaccent);
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease;
    }

    &:hover {
      &::before {
        left: 100%;
      }
    }
  }

  &--close {
    --size: 3em;
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    height: var(--size);
    width: var(--size);
    padding: 0.8em;
    border-radius: 50%;
    background-color: black;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease 50ms;

    img {
      @include fitimg;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#ffffff, 0.3);
    backdrop-filter: blur(4px);
    z-index: 670;
    display: flex;
    align-items: center;
  }

  &--message {
    width: 100%;
    height: 20vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &.success {
      background-color: var(--color-success);
      color: black;
    }

    &.error {
      background-color: var(--color-error);
    }

    &__text {
      font-weight: 700;
      font-size: 1.25em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .form {
    width: 62%;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .form {
    width: 30em;
  }
}
