.relaciones {
  min-height: 100vh;

  &--titleContainer {
    margin: 0 auto;
    padding: var(--container-padding);
  }

  &--content {
    display: flex;
  }

  &--brand {
    flex-basis: 30%;
    font-size: 5em;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 1rem;
    // margin-bottom: 1em;

    &__logo {
      width: 1em;
      height: 100%;

      img {
        object-fit: contain;
        width: 100%;
      }
    }

    p {
      transform: rotate(-180deg);
      writing-mode: vertical-lr;
    }
  }

  &--list {
    flex-basis: 70%;
  }

  &--item {
    border-bottom: solid black thin;
    padding: 1em 0 2em;
    // margin-bottom: 0.5em;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &__number {
      font-size: 4em;
      // color: var(--color-nygaccent);
      position: relative;
      height: 1em;
      // border: solid red;
      margin-bottom: 0.5rem;
      overflow: hidden;

      span {
        // border: solid blue thin;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &__body {
      font-weight: 300;
      font-size: 0.9em;

      .shopchars {
        opacity: 0;
      }

      .bullet {
        display: inline-block;
        height: 0.5em;
        width: 0.5em;
        border-radius: 50%;
        background-color: var(--color-nygaccent);
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .relaciones {
    &--content {
      column-gap: 1em;
    }

    &--brand {
      font-size: 8em;
      flex-basis: 1em;
      flex-shrink: 0;
      flex-grow: 0;
    }

    &--list {
      flex-basis: auto;
    }

    &--item {
      display: flex;
      align-items: center;
      column-gap: 2em;
      border-bottom: solid black thin;
      padding: 2em 0;
      // border: solid red;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      &__number,
      &__body {
        flex-basis: 50%;
      }

      &__number {
        font-size: 6em;
      }

      &__body {
        font-size: 1em;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .relaciones {
    &--brand {
      font-size: calc((100vh - var(--nav-height)) / 7.5);
    }

    &--content {
      column-gap: 2em;
    }

    &--item {
      justify-content: center;
      // justify-content: space-around;
      // justify-content: space-between;

      &__number,
      &__body {
        // border: solid blue thin;
        // flex-shrink: 0;
        // flex-grow: 0;
      }

      &__number {
        text-align: center;
        font-size: 8em;
        // flex-grow: 0;
      }

      &__body {
        // flex-shrink: 0;
        // flex-basis: 20em;
        // width: 20em;
      }
    }
  }
}
