.clientes {
  // height: calc(100vh - #{$nav-height});
  margin-bottom: var(--section-margin);

  &--titleContainer {
    padding: var(--container-padding);
  }

  ul {
    display: flex;
    align-items: center;
    height: 150px;
  }

  li {
    // border: solid blue thin;

    img {
      // @include fitimg;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}
