.hero {
  margin-top: $nav-height;
  height: calc(100vh - #{$nav-height});
  --strapsize: 3em;
  overflow: hidden;

  .container {
    padding: 0;
    padding-top: 2em;
  }

  &--canvas {
    position: relative;
    height: 50vh;
    overflow: hidden;

    &__container {
      height: calc(100% - var(--strapsize));
      width: 100%;
      background-image: url("/static/img/transition/hero0-400w.jpeg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (min-width: 500px) {
        background-image: url("/static/img/transition/hero0-550w.jpeg");
      }
      @media screen and (min-width: 550px) {
        background-image: url("/static/img/transition/hero0-640w.jpeg");
      }
      @media screen and (min-width: 640px) {
        background-image: url("/static/img/transition/hero0-768w.jpeg");
      }
      @media screen and (min-width: 768px) {
        background-image: url("/static/img/transition/hero0-964w.jpeg");
      }
      @media screen and (min-width: 964px) {
        background-image: url("/static/img/transition/hero0-1200w.jpeg");
      }

      canvas {
        width: 100%;
        height: 100%;

        &[data-state="loading"] {
          display: none;
        }

        &[data-state="ready"] {
          display: block;
        }
      }
    }
  }

  &--confianza,
  &--construye {
    font-size: var(--strapsize);
    user-select: none;
  }

  &--confianza {
    position: relative;
    color: black;
    color: var(--color-nygaccent);
    text-align: center;
    line-height: 1;

    #conf {
      display: block;
      animation: reveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
    }

    #que {
      position: absolute;
      color: black;
      font-size: 0.5em;
      font-weight: 700;
      left: 50%;
      bottom: -0.6em;
      transform: translateX(-50%);
    }
  }

  &--construye {
    position: absolute;
    top: 1.25em;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    height: 1.1em;
    width: 7em;
    overflow: hidden;

    #construye {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 100%;
      text-align: center;
    }
  }

  &--description {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: var(--container-padding);
    padding-bottom: 2em;
    margin-top: 3em;

    &__main {
      font-weight: 300;
      // font-size: 1.125em;
      padding-bottom: 1.5em;
      border-bottom: solid var(--color-nygaccent) 1px;
    }

    &__list {
      font-size: 1em;
      margin-top: 1em;
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 25em) {
  .hero {
    --strapsize: 3.5em;
  }
}

@media screen and (min-width: 27.3em) {
  .hero {
    --strapsize: 4em;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .hero {
    // height: auto;
    --strapsize: 5em;

    &--confianza {
      #que {
        // font-weight: 300;
      }
    }

    &--canvas {
      height: 55vh;
    }

    &--description {
      &__main {
        font-size: 1.125em;
        // max-width: 20em;
      }

      .row {
        margin-top: 2em;
        width: 50%;
        margin-left: auto;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .hero {
    --strapsize: 8em;

    .container {
      display: grid;
      grid-template-columns: repeat(16, 1fr);
      grid-template-rows: repeat(5, 1fr);
      padding: var(--container-padding);
      padding-top: 2em;
      padding-right: 0;
    }

    &--confianza {
      top: -1.125em;
      text-align: left;
      // grid-row: 1 / 2;

      #que {
        position: initial;
        font-weight: 300;
      }
    }

    &--construye {
      top: 0;
      left: 0;
      transform: translateX(0);
      z-index: 2;

      #construye {
        text-align: left;
      }
    }

    &--canvas {
      height: auto;
      grid-column: 4 / 17;
      grid-row: 2 / 6;
      overflow: visible;

      &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--description {
      margin-top: 0;
      grid-column: 1 / 3;
      grid-row: 1 / 6;
      padding: 0;
      padding-bottom: 3em;

      .row {
        display: block;
        width: 100%;
        margin-left: 0;
      }

      &__main {
        font-size: 1.25em;
      }
    }
  }
}
